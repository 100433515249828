const BACKEND_URL = "https://ms-allsk-641ff302dbf9.herokuapp.com/";

const SESSION_DATA = "ALLSK-MEMBER-SESSIONS";

const ACCOUNT_DATA = "ALLSK-ACCOUNT-DATA";

const AWS_INFRASTRUCTURE_BUCKET = "allsk";

const AWS_INFRASTRUCTURE_REGION = "ap-northeast-1";

const AWS_ACCESS_KEY = "AKIARHJJM7OII767S65O";

const AWS_SECRET_KEY = "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";

const THEME_KEY = "ALLSK-THEME"

export {
    BACKEND_URL,
    SESSION_DATA,
    ACCOUNT_DATA,
    AWS_INFRASTRUCTURE_BUCKET,
    AWS_INFRASTRUCTURE_REGION,
    AWS_ACCESS_KEY,
    AWS_SECRET_KEY,
    THEME_KEY
};